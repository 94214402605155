import { ColumnType, SorterResult } from 'antd/es/table/interface';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMemo } from 'react';
// @ts-ignore
import { ReactComponent as AlternativesIcon } from '../../assets/img/alternative.svg';
import { useGetProductsQuery, useUpdateProductStatusMutation } from '../../persistence/productApiSlice.ts';
import { GetProductsRequest, Product, ProductOrderBy } from '../../persistence/model/Product.ts';
import { translate } from '../../translations/TranslationUtils.ts';
import ProductRenderer from './ProductRenderer.tsx';
import { PortalSortOrder } from '../../persistence/model/Common.ts';
import { Button, Switch, Table, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, HourglassOutlined } from '@ant-design/icons';
import useCustomerContext from '../../persistence/useCustomerContext.ts';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../persistence/store.ts';
import { setPage } from './ProductsTableSlice.ts';
import { toSorter } from '../../util/PortalUtil.ts';
import { useNavigate } from 'react-router-dom';

const ITEMS_PER_PAGE = 25;

const isInactive = (product: Product): boolean => {
  return (product.pendingActive != null && product.pendingActive === 0) || !product.active;
};

const isPending = (product: Product): boolean => {
  return product.pendingActive != null;
};

const getTooltipTitle = (product: Product): string => {
  if (isPending(product)) {
    return 'product.table.pending';
  } else if (isInactive(product)) {
    return 'product.table.contactAdmin';
  }

  return 'product.table.deactivate';
};

const convertTableSortToPortalSort = (sort?: SorterResult<Product>): { orderBy?: string; order?: PortalSortOrder } => {
  return sort?.order ? { orderBy: `${sort.columnKey}`, order: sort.order === 'ascend' ? PortalSortOrder.ASC : PortalSortOrder.DESC } : {};
};

const ProductsTable = (): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customer } = useCustomerContext();
  const { page, searchCriteria, filter, sort } = useSelector((state: RootState) => state.product);
  const { data: ordersResponse, isFetching } = useGetProductsQuery(
    {
      customerId: customer?.id,
      activeFilter: filter,
      offset: (page - 1) * ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
      comboSearch: searchCriteria || undefined,
      ...convertTableSortToPortalSort(sort),
    } as GetProductsRequest,
    { skip: !customer }
  );
  const [updateProductStatus, { isLoading: statusUpdating }] = useUpdateProductStatusMutation();

  const columns = useMemo(
    () =>
      [
        {
          key: ProductOrderBy.name,
          dataIndex: 'name',
          title: translate(intl, 'product.table.product'),
          sorter: true,
          ...toSorter(sort, ProductOrderBy.name),
          render: (_, record) => <ProductRenderer productNumber={record.code} productName={record.name} externalItemId={record.externalItemId} />,
        },
        {
          key: 'alternative',
          width: '160px',
          dataIndex: 'alternativeGroup',
          title: translate(intl, 'product.table.alternative'),
          render: (alternativeGroup, record) =>
            alternativeGroup && record.active && !isPending(record)  && (
              <Button
                style={{ backgroundColor: '#00943C40' }}
                onClick={() => navigate(`/product-alternatives?groupName=${alternativeGroup}`)}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <AlternativesIcon />
                  <span>
                    <FormattedMessage id={'productAlternatives.button'} />
                  </span>
                </div>
              </Button>
            ),
        },
        {
          key: 'active',
          title: translate(intl, 'product.table.active'),
          dataIndex: 'active',
          width: 80,
          render: (_, record) => (
            <Tooltip title={translate(intl, getTooltipTitle(record))}>
              <Switch
                checked={!isInactive(record)}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={isPending(record) ? <HourglassOutlined /> : <CloseOutlined />}
                disabled={isInactive(record)}
                onChange={(value) => updateProductStatus({ productId: record.id, status: Number(value) })}
                loading={isPending(record)}
              />
            </Tooltip>
          ),
        },
      ] as ColumnType<Product>[],
    [intl, sort, navigate, updateProductStatus]
  );

  return (
    <Table<Product>
      columns={columns}
      size="small"
      dataSource={ordersResponse?.data}
      loading={isFetching || statusUpdating}
      rowKey="id"
      pagination={{
        current: page,
        onChange: (pageNum) => dispatch(setPage(pageNum)),
        pageSize: ITEMS_PER_PAGE,
        position: ['bottomRight'],
        hideOnSinglePage: true,
        showSizeChanger: false,
        total: ordersResponse?.metadata?.totalCount || 0,
      }}
    />
  );
};

export default ProductsTable;
