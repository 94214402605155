import { flatten } from 'flat';
import { IntlShape } from 'react-intl';
import { Language } from '../persistence/model/Common.ts';
import en from './en';
import et from './et';

export type PrimitiveType = string | number | boolean | null | undefined | Date;

const translationUtils = {
  [Language.EN]: en,
  [Language.ET]: et,
  ro: {} as any,
};

export function getLocaleFromStorage(): Language {
  return localStorage.getItem('locale') as Language;
}

export function storeLocale(locale: Language): void {
  localStorage.setItem('locale', locale);
}

export function translate(intl: IntlShape, key: string, values: Record<string, PrimitiveType> | undefined = undefined): string {
  return intl.formatMessage({ id: key }, values);
}

export function translateUnit(intl: IntlShape, unit: string): string {
  return unit ? translate(intl, `unit.${unit}`) : '-';
}

export default function getTranslations(locale: Language): Record<string, string> {
  const translation = translationUtils[locale] as never;
  return flatten(translation);
}
