import './ProductRenderer.css';
import { ReactNode } from 'react';

type ProductRendererProps = {
  productNumber?: string;
  productName?: string;
  externalItemId?: string;
  ean?: string;
  label?: string | ReactNode;
};

const ProductRenderer = ({ productNumber, productName, externalItemId, ean, label }: ProductRendererProps): JSX.Element => {
  const combinedName = [productNumber, productName].filter((s) => s).join(' - ');

  return (
    <div className="product-renderer-container">
      <div className="product-renderer-main-data" title={combinedName}>
        {label ?? combinedName}
      </div>
      {!!externalItemId && <div className="product-renderer-additional-data">{externalItemId}</div>}
      {!!ean && <div className="product-renderer-additional-data">{ean}</div>}
    </div>
  );
};

export default ProductRenderer;
