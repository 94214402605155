import { Complaint, ComplaintStatus } from '../../../persistence/model/Complaint.ts';
import { Button, Card, Flex, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { translateUnit } from '../../../translations/TranslationUtils.ts';
import React, { useCallback, useMemo, useState } from 'react';
import FlexBox from '../../common/FlexBox.tsx';
import LabeledValue from '../../common/LabeledValue.tsx';
import './ComplaintDetails.css';
import dayjs from 'dayjs';
import { formatDateTime } from '../../../util/DateUtil.ts';
import { complaintApiSlice, useGetComplaintFilesQuery } from '../../../persistence/complaintApiSlice.ts';
import { useDispatch, useSelector } from 'react-redux';
import ComplaintStatusTag from '../ComplaintStatusTag.tsx';
import ComplaintDecision from './ComplaintDecision.tsx';
import { selectCurrentUserIsEstiko } from '../../../persistence/authSlice.ts';
import ComplaintDelete from '../edit/ComplaintDelete.tsx';
import ComplaintAddSolutionModal from './ComplaintAddSolutionModal.tsx';
import ComplaintAddCancelDecisionModal from './ComplaintAddCancelDecisionModal.tsx';

type ComplaintDetailsProps = {
  complaint: Complaint;
};

const ComplaintDetails = ({ complaint }: ComplaintDetailsProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isEstikoUser = useSelector(selectCurrentUserIsEstiko);
  const [isResolveModalOpen, setIsResolveModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { data: attachments } = useGetComplaintFilesQuery(complaint.id);
  const hasDecision = useMemo(
    () => [ComplaintStatus.SOLVED, ComplaintStatus.REJECTED, ComplaintStatus.CANCELLED].some((s) => s === complaint.status),
    [complaint]
  );

  const downloadFile = useCallback(
    (fileId: number) => {
      // @ts-ignore
      dispatch(complaintApiSlice.util.prefetch('getComplaintFileContent', { complaintId: complaint.id, fileId }, { force: true }));
    },
    [dispatch, complaint]
  );

  return (
    <>
      <FlexBox direction="horizontal" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography.Title level={4}>
          <FormattedMessage id="complaint.details.title" values={{ id: complaint.id }} />
        </Typography.Title>
        <ComplaintStatusTag size={'large'} status={complaint.status} />
      </FlexBox>
      <FlexBox direction="vertical" gap={0}>
        <Flex justify={'stretch'} className={'complaint-details'}>
          <Flex vertical={true} className={'complaint-details-block'}>
            <LabeledValue label="complaint.details.customer" value={complaint.customerName} />
            <LabeledValue label="complaint.details.createdAt" value={formatDateTime(intl, dayjs(complaint.createdAt))} />
            <LabeledValue label="complaint.details.modifiedAt" value={formatDateTime(intl, dayjs(complaint.modifiedAt))} />
          </Flex>
          <Flex vertical={true} className={'complaint-details-block bordered-block'}>
            <LabeledValue label="complaint.details.packageNumber" value={complaint.productionId} />
            <LabeledValue label="complaint.details.purchaseOrderNumber" value={complaint.purchaseOrderNum} />
          </Flex>
          <Flex vertical={true} className={'complaint-details-block bordered-block'}>
            <LabeledValue label="complaint.details.itemCode" value={complaint.itemCode} />
            <LabeledValue label="complaint.details.quantity" value={`${complaint.quantity} ${translateUnit(intl, complaint.unit)}`} />
            <LabeledValue label="complaint.details.productionNumber" value={complaint.roll} />
          </Flex>
        </Flex>
        <Typography.Title level={4}>
          <FormattedMessage id="complaint.details.complaintDetails.title" />
        </Typography.Title>
        <Card>
          <div className={'details-grid'}>
            <Typography.Text>
              <FormattedMessage id={'complaint.details.complaintDetails.content'} />:
            </Typography.Text>
            <div>{complaint.complaintContent}</div>
            <Typography.Text>
              <FormattedMessage id={'complaint.details.complaintDetails.claim'} />:
            </Typography.Text>
            <div>{complaint.complaintClaim}</div>
            <Typography.Text>
              <FormattedMessage id={'complaint.details.complaintDetails.attachments'} />:
            </Typography.Text>
            <Flex vertical={true} align={'start'}>
              {attachments?.data?.map((file) => (
                <Button
                  key={file.id}
                  size={'small'}
                  type={'link'}
                  style={{ padding: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile(file.id);
                  }}
                >
                  {file.fileName}
                </Button>
              ))}
            </Flex>
          </div>
        </Card>
        {hasDecision && <ComplaintDecision complaint={complaint} />}
        <ComplaintAddSolutionModal complaintId={complaint.id} isModalOpen={isResolveModalOpen} onModalClose={() => setIsResolveModalOpen(false)} />
        <ComplaintAddCancelDecisionModal complaint={complaint} isModalOpen={isCancelModalOpen} onModalClose={() => setIsCancelModalOpen(false)} />
        {isEstikoUser && complaint.status != ComplaintStatus.CANCELLED && (
          <FlexBox direction={'horizontal'} style={{ justifyContent: 'end', padding: '20px 0' }}>
            <ComplaintDelete complaint={complaint} onSuccess={() => setIsCancelModalOpen(true)} />
            {complaint.status === ComplaintStatus.UNDER_REVIEW && (
              <Button type="primary" className="uppercase" onClick={() => setIsResolveModalOpen(true)}>
                <FormattedMessage id="complaint.action.resolveButton" />
              </Button>
            )}
          </FlexBox>
        )}
      </FlexBox>
    </>
  );
};

export default ComplaintDetails;
