import { useMemo, useState } from 'react';
import { Button, Checkbox, DatePicker, Dropdown, Tabs, Typography, Row, Col, Space, Tooltip, App } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PortalSearchBox from '../common/PortalSearchBox';
import { translate } from '../../translations/TranslationUtils.ts';
import InventoryTable from './InventoryTable';
import { selectCurrentUser } from '../../persistence/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { UserType } from '../../persistence/model/Auth';
import CallOffCartSummary from './call-off-cart/CallOffCartSummary';
import { CallOffCart } from './call-off-cart/CallOffCartLogic';
import CallOffCartBar from './call-off-cart/CallOffCartBar';
import './Inventory.css';
import { useNavigate } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { useDownloadInventoryMutation } from '../../persistence/inventoryApiSlice';
import { formatRequestDate } from '../../util/DateUtil';
import saveAs from 'file-saver';
import CustomerFilter from '../common/customer-filter/CustomerFilter.tsx';
import { RootState } from '../../persistence/store.ts';
import useCustomerContext from '../../persistence/useCustomerContext.ts';
import { setDateRange, setInStockOnly, setOrderSearchCriteria, setPage, setProductSearchCriteria } from './InventoryTableSlice.ts';

const { RangePicker } = DatePicker;

const EMPTY_CALL_OFF_CART = {
  items: {},
};

enum InventoryTab {
  OPEN_ORDERS = 'OPEN_ORDERS',
  ALL_ORDERS = 'ALL_ORDERS',
}

type InventoryProps = {
  checkout?: boolean;
};

const Inventory = ({ checkout }: InventoryProps): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const { customer } = useCustomerContext();
  const { productSearchCriteria, orderSearchCriteria, dateRange, inStockOnly } = useSelector((state: RootState) => state.inventory);
  const [selectedTab, setSelectedTab] = useState(InventoryTab.OPEN_ORDERS);
  const [callOffCart, setCallOffCart] = useState<CallOffCart>(EMPTY_CALL_OFF_CART);
  const [downloadInventory, { isLoading: downloading }] = useDownloadInventoryMutation();
  const itemsInCart = Object.keys(callOffCart.items).length;

  const tabItems = useMemo(
    () => [
      {
        key: InventoryTab.OPEN_ORDERS,
        label: translate(intl, 'inventory.tab.openOrders'),
      },
      {
        key: InventoryTab.ALL_ORDERS,
        label: translate(intl, 'inventory.tab.allOrders'),
      },
    ],
    [intl]
  );

  const onDownload = async (fileType: 'XLSX' | 'CSV') => {
    const { data, error } = (await downloadInventory({
      'customer-id': customer?.id,
      'from-date': formatRequestDate(dateRange[0]),
      'to-date': formatRequestDate(dateRange[1]),
      'include-settled': selectedTab === InventoryTab.ALL_ORDERS,
      'file-type': fileType,
    })) as { data?: Blob; error?: any };

    if (error) {
      message.error(translate(intl, 'inventory.downloadFailed', { message: error.error }));
    } else {
      saveAs(data, `${customer?.name}.${fileType.toLowerCase()}`);
    }
  };

  const renderDownload = () => {
    const downloadOptions = [
      { key: 'XLS', label: 'XLSX', onClick: () => onDownload('XLSX') },
      { key: 'CSV', label: 'CSV', onClick: () => onDownload('CSV') },
    ];

    return (
      <Dropdown menu={{ items: downloadOptions }} trigger={['click']} key="download-dropdown" disabled={!customer || downloading}>
        <Tooltip title={<FormattedMessage id="inventory.download" />}>
          <Button loading={downloading} type="primary" icon={<DownloadOutlined />} className="uppercase" onClick={(e) => e.preventDefault()} />
        </Tooltip>
      </Dropdown>
    );
  };

  return !checkout ? (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Title level={4}>
          <FormattedMessage id="inventory.title" />
        </Typography.Title>
        <Tabs items={tabItems} activeKey={selectedTab} onChange={(value) => setSelectedTab(value as InventoryTab)} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} className={itemsInCart ? 'extra-padding-for-call-off-bar' : undefined}>
          <Row gutter={[10, 10]}>
            <Col xs={{ span: 12, order: 1 }} xl={{ span: 10, order: 1 }} xxl={{ span: 5, order: 1 }}>
              <CustomerFilter
                style={{ width: '100%' }}
                disabled={!!itemsInCart}
                placeholder={translate(intl, 'callOff.clientSelectPlaceholder')}
                onChange={() => dispatch(setPage(1))}
              />
            </Col>
            <Col xs={{ span: 10, order: 3 }} xl={{ span: 10, order: 3 }} xxl={{ span: 6, order: 2 }}>
              <PortalSearchBox
                value={productSearchCriteria}
                placeholder="inventory.productSearchPlaceholder"
                onChange={(e) => dispatch(setProductSearchCriteria(e.target.value))}
              />
            </Col>
            <Col xs={{ span: 8, order: 4 }} xl={{ span: 10, order: 4 }} xxl={{ span: 5, order: 3 }}>
              <PortalSearchBox
                value={orderSearchCriteria}
                placeholder="inventory.orderSearchPlaceholder"
                onChange={(e) => dispatch(setOrderSearchCriteria(e.target.value))}
              />
            </Col>
            <Col xs={{ span: 12, order: 2 }} xl={{ span: 6, order: 2 }} xxl={{ span: 3, order: 4 }}>
              <RangePicker format={translate(intl, 'config.dateFormat')} value={dateRange} onChange={(v) => dispatch(setDateRange(v))} allowClear={false} />
            </Col>
            <Col style={{ width: "100%" }} xs={{ span: 5, order: 5 }} xl={{ span: 4, order: 5 }} xxl={{ span: 5, order: 5 }}>
              <Space style={{ justifyContent: 'space-between', width: '100%'}}>
                <Checkbox
                  checked={inStockOnly}
                  onChange={(e) => {
                    dispatch(setInStockOnly(e.target.checked));
                    dispatch(setPage(1));
                  }}
                >
                  <FormattedMessage id="inventory.showInStockOnly" />
                </Checkbox>
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 12, justifySelf: "end" }}>{renderDownload()}</div>
              </Space>
            </Col>
          </Row>
          <InventoryTable
            callOffCart={callOffCart}
            setCallOffCart={setCallOffCart}
            readOnly={user?.type === UserType.ESTIKO}
            allOrders={selectedTab === InventoryTab.ALL_ORDERS}
          />
        </div>
      </div>
      {!!itemsInCart && (
        <CallOffCartBar callOffCart={callOffCart} onCancel={() => setCallOffCart(EMPTY_CALL_OFF_CART)} onSubmit={() => navigate('/call-offs/new')} />
      )}
    </>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography.Title level={4}>
        <FormattedMessage id="callOffCart.summary.table.title" />
      </Typography.Title>
      <CallOffCartSummary customerId={customer?.id} callOffCart={callOffCart} setCallOffCart={setCallOffCart} onCancel={() => navigate('/inventory')} />
    </div>
  );
};

export default Inventory;
