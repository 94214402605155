import { apiSlice } from './apiSlice.ts';
import { CommonApiResponse } from './model/Common.ts';
import {
  AddOrderProductRequest,
  ContractAgreementRequest,
  DetailedOrder,
  GetOrdersRequest,
  Order,
  OrderComment,
  PlaceOrderRequest,
  PurchaseOrderProduct,
  SalesOrderLiveGroup,
  SalesOrderProduct,
  UpdateOrderRequest,
  UpdatePurchaseOrderProductRequest,
} from './model/Order.ts';

export const orderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<CommonApiResponse<Order[]>, GetOrdersRequest>({
      query: (payload) => ({
        url: '/api/order',
        method: 'POST',
        body: payload,
      }),
      providesTags: ['ALL', 'Orders'],
    }),
    createOrder: builder.mutation<CommonApiResponse<DetailedOrder>, number>({
      query: (customerId) => ({
        url: `/api/order/create/${customerId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Orders'],
    }),
    updateOrder: builder.mutation<void, { orderId: number; body: UpdateOrderRequest }>({
      query: ({ orderId, body }) => ({
        url: `/api/order/${orderId}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (res, error, { orderId }) => [{ type: 'Orders' }, { type: 'PurchaseOrderProducts', id: orderId }],
    }),
    placeOrder: builder.mutation<void, { orderId: number; body: PlaceOrderRequest }>({
      query: ({ orderId, body }) => ({
        url: `/api/order/${orderId}/ordered`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Orders'],
    }),
    confirmOrder: builder.mutation<CommonApiResponse<DetailedOrder>, { orderId: number; body: ContractAgreementRequest }>({
      query: ({ orderId, body }) => ({
        url: `/api/order/${orderId}/confirm`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Orders'],
    }),
    declineOrder: builder.mutation<CommonApiResponse<DetailedOrder>, { orderId: number; reason: string }>({
      query: ({ orderId, reason }) => ({
        url: `/api/order/${orderId}/decline`,
        method: 'POST',
        body: { content: reason },
      }),
      invalidatesTags: ['Orders'],
    }),
    deleteOrder: builder.mutation<void, number>({
      query: (orderId) => ({
        url: `/api/order/${orderId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: ['Orders'],
    }),
    getOrder: builder.query<CommonApiResponse<DetailedOrder>, number>({
      query: (orderId) => ({
        url: `/api/order/${orderId}`,
        method: 'GET',
      }),
      providesTags: (_, __, orderId) => ['ALL', { type: 'Orders', id: orderId }],
    }),
    addOrderProduct: builder.mutation<CommonApiResponse<PurchaseOrderProduct>, { orderId: number; body: AddOrderProductRequest }>({
      query: ({ orderId, body }) => ({
        url: `/api/order/${orderId}/products/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { orderId }) => [{ type: 'PurchaseOrderProducts', id: orderId }],
    }),
    updateOrderProduct: builder.mutation<CommonApiResponse<PurchaseOrderProduct>, { orderId: number; rowId: number; body: UpdatePurchaseOrderProductRequest }>({
      query: ({ orderId, rowId, body }) => ({
        url: `/api/order/${orderId}/products/${rowId}/update`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { orderId }) => [{ type: 'PurchaseOrderProducts', id: orderId }],
    }),
    deleteOrderProduct: builder.mutation<void, { orderId: number; rowId: number }>({
      query: ({ orderId, rowId }) => ({
        url: `/api/order/${orderId}/products/${rowId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, { orderId }) => [{ type: 'PurchaseOrderProducts', id: orderId }],
    }),
    getPurchaseOrderProducts: builder.query<CommonApiResponse<PurchaseOrderProduct[]>, number>({
      query: (orderId) => ({
        url: `/api/order/${orderId}/products`,
        method: 'GET',
      }),
      providesTags: (_, __, orderId) => ['ALL', { type: 'PurchaseOrderProducts', id: orderId }],
    }),
    getSalesOrderProducts: builder.query<CommonApiResponse<SalesOrderProduct[]>, number>({
      query: (orderId) => ({
        url: `/api/order/${orderId}/products/sales`,
        method: 'GET',
      }),
      providesTags: (_, __, orderId) => ['ALL', { type: 'SalesOrderProducts', id: orderId }],
    }),
    getSalesOrderLiveRows: builder.query<CommonApiResponse<SalesOrderLiveGroup[]>, number>({
      query: (orderId) => ({
        url: `/api/order/${orderId}/products/live`,
        method: 'GET',
      }),
    }),
    getOrderComments: builder.query<CommonApiResponse<OrderComment[]>, number>({
      query: (orderId) => ({
        url: `/api/order/${orderId}/comments`,
        method: 'GET',
      }),
      providesTags: (_, __, orderId) => ['ALL', { type: 'PurchaseOrderComments', id: orderId }],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useCreateOrderMutation,
  useConfirmOrderMutation,
  useDeclineOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  usePlaceOrderMutation,
  useAddOrderProductMutation,
  useUpdateOrderProductMutation,
  useDeleteOrderProductMutation,
  useGetPurchaseOrderProductsQuery,
  useGetSalesOrderProductsQuery,
  useGetOrderCommentsQuery,
  useGetSalesOrderLiveRowsQuery,
} = orderApiSlice;
