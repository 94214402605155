import React from 'react';
import { Line, LineConfig } from '@ant-design/charts';
import { useIntl } from 'react-intl';
import { translate } from '../../translations/TranslationUtils';
import { Card } from 'antd';
import DashboardTag from './DashboardTag';
import dayjs from 'dayjs';
import './DashboardCreditChart.css';
import { useGetCreditChartStatsQuery } from '../../persistence/dashboardApiSlice.ts';
import useCustomerContext from '../../persistence/useCustomerContext.ts';
import { formatPortalNumber } from '../../util/NumberUtil.ts';

export enum CreditChartCategory {
  CREDIT_LIMIT = 'CREDIT_LIMIT',
  CREDIT_BALANCE = 'CREDIT_BALANCE',
  CREDIT_PREDICTION = 'CREDIT_PREDICTION', // TODO no info atm
}

export type CreditChartData = {
  date: string;
  value: number;
  category: CreditChartCategory;
};

const formatTimelineDate = (date: string) => {
  return dayjs(date).toISOString();
};

const DashboardCreditChart = (): JSX.Element => {
  const intl = useIntl();
  const { customer } = useCustomerContext();
  const { data: creditChartStatsResponse, isFetching: isFetching } = useGetCreditChartStatsQuery(customer?.id, { skip: !customer?.id });
  const chartStats = creditChartStatsResponse?.data;
  const chartUsedLimitStats = chartStats?.usedLimitTimeline.map((stat) => ({
    date: formatTimelineDate(stat.date),
    value: stat.value,
    category: CreditChartCategory.CREDIT_BALANCE,
  }));
  const chartMaxLimitStats = chartStats?.usedLimitTimeline.map((stat) => ({
    date: formatTimelineDate(stat.date),
    value: chartStats.creditMax,
    category: CreditChartCategory.CREDIT_LIMIT,
  }));

  const timeline = chartMaxLimitStats?.concat(chartUsedLimitStats).sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1));
  if (chartStats?.usedLimitTimeline?.length && chartStats?.predictedTimeline?.length) {
    timeline?.push({
      date: chartUsedLimitStats[chartUsedLimitStats?.length - 1]?.date,
      value: chartUsedLimitStats[chartUsedLimitStats?.length - 1]?.value,
      category: CreditChartCategory.CREDIT_PREDICTION,
    });

    chartStats?.predictedTimeline?.map(
      (p) =>
        timeline?.push(
          {
            date: formatTimelineDate(p?.date),
            value: p.value,
            category: CreditChartCategory.CREDIT_PREDICTION,
          },
          {
            date: formatTimelineDate(p.date),
            value: chartStats.creditMax,
            category: CreditChartCategory.CREDIT_LIMIT,
          }
        )
    );
  }

  const config: LineConfig = {
    data: timeline ? timeline : [],
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    point: {
      size: 0,
    },
    xAxis: {
      type: 'time',
      label: {
        formatter: (value) => {
          const date = dayjs(value, 'YYYY.MM.DD');
          const year = date.year();
          const month = date.month() + 1;
          const monthString = translate(intl, `common.shortMonth.${month}`);

          return `${monthString} ${year}`;
        },
      },
    },
    yAxis: {
      max: chartStats?.creditMax < 100000 ? 100000 : undefined,
      label: {
        formatter: (value) => (chartStats?.creditMax > 1000000 ? `€ ${Number(value) / 1e6}M` : `€ ${formatPortalNumber(Number(value))}`),
      },
      grid: {
        line: {
          style: {
            lineDash: [4, 4],
          },
        },
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'top-left',
      itemName: {
        formatter: (value) => translate(intl, `dashboard.creditChart.category.${value}`),
        style: {
          fill: '#000',
          fontSize: 16,
        },
      },
      padding: [0, 0, 20, 0],
    },
    lineStyle: {
      lineWidth: 4,
    },
    color: ['#B52B24', '#001F5F', '#C1E2FD'],
    tooltip: false,
    autoFit: true,
  };

  return (
    <Card className="dashboard-credit-chart-card" bodyStyle={{ height: '100%' }} loading={isFetching}>
      <div className="dashboard-credit-chart-grid">
        <Line {...config} className="dashboard-credit-chart" />
        <DashboardTag
          label={'dashboard.creditChart.category.CREDIT_LIMIT'}
          value={`${formatPortalNumber(chartStats?.creditMax ?? 0, { fractionDigits: 0 })} €`}
        />
        <DashboardTag
          label={'dashboard.creditChart.category.CREDIT_BALANCE'}
          value={`${formatPortalNumber(chartStats?.usedLimit ?? 0, { fractionDigits: 0 })} €`}
        />
        <DashboardTag label={'dashboard.creditChart.paymentCriteria'} value={`${chartStats?.paymentTermId ?? 'NaN'}`} />
        <DashboardTag
          label={'dashboard.creditChart.debt'}
          value={`${formatPortalNumber(chartStats?.debt ?? 0, { fractionDigits: 0 })} €`}
          className={`${chartStats?.debt ?? 0 ? 'dashboard-tag-debt' : ''}`}
        />
        <DashboardTag
          label={'dashboard.creditChart.averageDelay'}
          value={translate(intl, 'dashboard.creditChart.averageDelayValue', { num: chartStats?.averagePaymentDelay ?? 0 })}
          className="dashboard-blue-tag dashboard-credit-wide-tag"
        />
      </div>
    </Card>
  );
};

export default DashboardCreditChart;
