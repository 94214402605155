import { apiSlice } from './apiSlice.ts';
import { AppSettingsResponse, AppSettingRequest } from './model/AppSetting.ts';
import { CommonApiResponse } from './model/Common.ts';

export const appSettingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    saveAppSetting: builder.mutation<void, AppSettingRequest>({
      query: (body) => ({
        url: `api/appsettings/save`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ApplicationSettings'],
    }),
    getAppSettings: builder.query<CommonApiResponse<AppSettingsResponse[]>, void>({
      query: () => ({
        url: `api/appsettings/list`,
        method: 'GET',
      }),
      providesTags: ['ALL', 'ApplicationSettings'],
    }),
  }),
});

export const {
  useSaveAppSettingMutation,
  useGetAppSettingsQuery,
} = appSettingsApiSlice