import { ComplaintType } from '../components/dashboard/DashboardComplaintChart.tsx';
import { CreditChartCategory } from '../components/dashboard/DashboardCreditChart.tsx';
import { DeliveryType } from '../components/dashboard/DashboardDeliveryChart.tsx';
import { AppSettingType } from '../persistence/model/AppSetting.ts';
import { UserType } from '../persistence/model/Auth.ts';
import { AxSalesStatus } from '../persistence/model/CallOff.ts';
import { ComplaintDecisionType, ComplaintStatus } from '../persistence/model/Complaint.ts';
import { OrderCommentType, OrderStatus, OrderType } from '../persistence/model/Order.ts';
import { ProductActiveFilter } from '../persistence/model/Product.ts';
import { UserTypeFilter } from '../persistence/model/User.ts';

const translations = {
  menu: {
    title: 'Menu',
    dashboard: 'Dashboard',
    orders: 'Orders',
    delivery: 'Deliveries',
    callOffs: 'Call-offs',
    inventory: 'Inventory',
    products: 'Products',
    complaints: 'Complaints',
    users: 'Users',
    contact: {
      title: 'Contact',
      sendMail: 'Send e-mail',
    },
  },
  login: {
    title: 'Log in',
    userName: 'Username',
    password: 'Password',
    forgotPassword: 'Forgot password',
    loginButton: 'Log in',
    loginError: 'Wrong username or password',
    usernameRequired: 'Username is required',
    passwordRequired: 'Password is required',
  },
  resetPasswordRequest: {
    title: 'Reset password',
    username: 'Enter username or email',
    sendRequest: 'Send',
    success: 'Password reset email has been sent, check your email for details',
    error: 'Failed to send password reset email',
  },
  resetPassword: {
    title: 'Password change',
    username: 'Username',
    password: 'Password',
    confirmPassword: 'Confirm password',
    success: 'Password successfully changed',
    error: 'Failed to change password',
    passwordRequired: 'Password is required',
    passwordMinLength: 'Password must be at least 8 characters',
    passwordMismatch: 'Passwords does not match',
    resetButton: 'Change',
  },
  completeInvitation: {
    title: 'Set password',
    username: 'Username',
    password: 'Password',
    confirmPassword: 'Confirm password',
    success: 'Password has been set',
    error: 'Unable to set password',
    passwordMismatch: 'Passwords does not match',
    send: 'Save',
    hashInvalid: 'Invitation link has expired or is invalid',
  },
  common: {
    add: 'Add',
    searchPlaceholder: 'Search',
    continue: 'Continue',
    customer: 'Customer',
    selectCustomer: 'Select customer',
    pageNotFound: 'Page not found',
    homeButton: 'Dashboard',
    yes: 'Yes',
    no: 'No',
    create: 'Create',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    back: 'Back',
    delete: 'Delete',
    allCustomers: 'All customers',
    showAllCustomers: 'Show all customers',
    noInternetConnection: 'No internet connection or response from the server',
    duplicate: 'Duplicate product',
    save: 'Save',
    shortMonth: {
      1: 'Jan',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec',
    },
  },
  config: {
    dateFormat: 'YYYY-MM-DD',
    longDateFormat: 'YYYY-MM-DD HH:mm:ss',
    longDateFormatWithoutSeconds: 'YYYY-MM-DD HH:mm',
  },
  dashboard: {
    newPortalNotification:
      'Welcome to the newly updated Estiko Customer Portal! With the recent updates, we have introduced a new look and various features to offer you an even more seamless user experience.{br}{br}' +
      'If you have any questions or concerns, do not hesitate to reach out to us.{br}{br}' +
      'We hope you enjoy our improved portal.',
    welcomeMessage: 'Welcome, {name}!',
    pendingOrders: 'Draft or unconfirmed orders',
    pendingOrdersValue: '{num} orders',
    pendingOrdersValueSingle: '{num} order',
    productsInInventory: 'Products in warehouse',
    productsInInventoryValue: '{num} products',
    productsInInventoryValueSingle: '{num} product',
    greenAlternativesTitle: 'Assess the impact of your packaging\n',
    greenAlternativesDescription:
      "The sustainability of your products is directly linked to your packaging decisions. Through our sustainability tool, you can explore and compare options to improve your packaging's recyclability. Upon completion, you can forward your intention to go more circular to your Sales Manager.",
    greenAlternativesButton: 'Learn more',
    orderChart: {
      deliveryType: {
        [DeliveryType.ON_TIME]: 'Delivered on time',
        [DeliveryType.EARLY]: 'Delivered early',
        [DeliveryType.LATE]: 'Delivered late',
      },
    },
    creditChart: {
      category: {
        [CreditChartCategory.CREDIT_LIMIT]: 'Credit limit',
        [CreditChartCategory.CREDIT_BALANCE]: 'Used limit',
        [CreditChartCategory.CREDIT_PREDICTION]: 'Limit forecast',
      },
      paymentCriteria: 'Payment criteria',
      debt: 'Debt',
      averageDelay: 'Average delay in payment',
      averageDelayValue: '{num} days',
      averageDelayValueSingle: '{num} day',
    },
    complaintChart: {
      noComplaints: 'No complaints in the last 12 months',
      type: {
        [ComplaintType.SUBMITTED]: 'Submitted complaints',
      },
    },
  },
  order: {
    add: 'Add',
    title: 'Order',
    purchaseOrders: 'Orders',
    search: 'Sales or purchase order number',
    myOrders: 'My orders only',
    send: 'Send',
    details: {
      customer: 'Customer',
      deliveryAddress: 'Delivery address',
      lastModifiedAt: 'Last modified',
      paymentTerm: 'Payment term',
      creditLimit: 'Credit limit',
      deliveryTerm: 'Delivery term',
      orderedRowsTitle: 'Order products',
      confirmationTitle: 'Order confirmation',
      commentsTitle: 'Comments',
      quotationRows: {
        product: 'Item',
        deliveryDate: 'Delivery date',
        quantity: 'Quantity',
        delivered: 'Delivered',
        unitPrice: 'Unit price (EUR)',
        price: 'Price (EUR)',
        offeredDeliveryDate: 'Offered date',
        calledOffQuantity: 'Called off',
      },
    },
    comment: {
      version: 'Version',
      comment: 'Comment',
      createdAt: 'Created at',
      createdBy: 'Created by',
      typeTitle: 'Type',
      type: {
        [OrderCommentType.CREATION_COMMENT]: 'Order creation',
        [OrderCommentType.DECLINE_COMMENT]: 'Order decline',
        [OrderCommentType.SYSTEM_COMMENT]: 'System',
      },
    },
    form: {
      title: 'New purchase order',
      selectOrderType: 'Select order type',
      orderTypeChangeTooltip: 'To choose product of another type, please add a new order',
      selectProduct: 'Select product',
      selectStandardProduct: 'Select standard product',
      selectProductPlaceholder: 'Type product name, code or article',
      standardProductName: '{quantity} {unit} in warehouse',
      itemsCount: '{count} items',
      orderItems: 'Items to be ordered',
      noItemsPlaceholder: 'Order has no items yet. Please search and select a product to add',
      itemsTable: {
        item: 'Item',
        quantity: 'Quantity',
        unit: 'Unit',
        deliveryDate: 'Delivery date',
        available: 'Available: {count} {unit}',
      },
      additionalInfo: 'Additional info',
      deliveryAddress: 'Delivery address',
      deliveryAddressPlaceholder: 'Select address',
      orderNumber: 'Order number',
      orderNumberPlaceholder: 'Your purchase order number',
      comment: 'Comment',
      commentPlaceholder: 'Comment',
      orderType: {
        [OrderType.DEFAULT]: 'Custom products',
        [OrderType.STANDARD]: 'Standard products',
      },
    },
    action: {
      confirm: {
        button: 'Confirm',
        title: 'Contract confirmation',
        term1: 'Agree with',
        term2: 'contract terms',
        text: 'By pressing the confirm button, you agree to the confirmation of the order and the order goes into production. If you want to check the details before confirming, press "Close"',
        textWithTerms:
          'If you agree to the terms of the contract and press the "Confirm" button, the order will go into production. If you want to check the details before confirmation, press "Close".',
        termsValidationError: 'Please accept the terms',
      },
      decline: {
        button: 'Decline',
        title: 'Decline contract',
        label: 'Reason for decline',
        placeholder: 'Please provide a reason',
      },
      delete: {
        title: 'Delete order',
        confirmation: 'Please confirm you want to delete this purchase order?',
      },
    },
    table: {
      customerName: 'Customer',
      purchaseOrderNum: 'Purchase order num',
      salesOrderNum: 'Sales order num',
      address: 'Address',
      created: 'Created',
      status: 'Status',
    },
    status: {
      [OrderStatus.DRAFT]: 'Draft',
      [OrderStatus.ORDERED]: 'Ordered',
      [OrderStatus.UNCONFIRMED]: 'Unconfirmed',
      [OrderStatus.REVIEW]: 'Review',
      [OrderStatus.CONFIRMED]: 'Confirmed',
      [OrderStatus.DELIVERED]: 'Delivered',
    },
    message: {
      creationFailed: 'Failed to create order',
      orderSuccess: 'Order sent',
      orderFailed: 'Delivery of order failed',
      confirmSuccess: 'Thank you! Your order has been sent to production',
      confirmFailed: 'Order confirmation failed',
      declineSuccess: 'Offer declined',
      declineFailed: 'Failed to decline order',
      deleteSuccess: 'Order deleted',
      deleteFailed: 'Failed to delete order',
    },
  },
  callOff: {
    title: 'Call-offs',
    clientSelectPlaceholder: 'Client',
    searchPlaceholder: 'Call-of ID or number',
    table: {
      callOffNr: 'ID',
      callOffOrderNumb: 'Call-off order number',
      createdAt: 'Created at',
      comment: 'Comments',
      calculatedStatus: 'Status',
    },
    status: {
      [AxSalesStatus.BACKORDER]: 'Open',
      [AxSalesStatus.DELIVERED]: 'Delivered',
      [AxSalesStatus.INVOICED]: 'Invoiced',
      [AxSalesStatus.CANCELED]: 'Cancelled',
    },
    details: {
      callOff: 'Call-off',
      customerName: 'Customer',
      createdAt: 'Created',
      comment: 'Comment',
      table: {
        title: 'Called-off products',
        product: 'Product',
        order: 'Order',
        quantity: 'Quantity',
        departureDate: 'Departure date',
        status: 'Status',
      },
    },
  },
  callOffCart: {
    bar: {
      productsInCallOff: 'Products in call-off: {productsInCallOff}',
      goToSummary: 'Go to summary',
    },
    addItemModal: {
      title: 'Product call-off',
      product: 'Product',
      amount: 'Quantity to be Issued',
      amountHelper: 'order balance: {itemsLeft}; in stock: {itemsInStock}',
      deliveryDate: 'Delivery date from Estiko warehouse',
      deliveryDateError: 'The selected quantity cannot be delivered before {minDeliveryDate}',
      addToCart: 'Add to call-off',
    },
    summary: {
      table: {
        title: 'Products to be called off',
        product: 'Product',
        order: 'Order',
        remainingQuantity: 'Order balance',
        inWarehouse: 'In warehouse',
        amount: 'Amount',
        deliveryDate: 'Delivery date',
      },
      additionalInfo: 'Additional information',
      callOffNr: 'Call-off number',
      callOffNrPlaceholder: 'Enter your call-off number',
      comments: 'Comments',
      commentsPlaceholder: 'Enter additional information',
      backToInventory: 'Back to inventory',
      submit: 'Submit',
      submitSuccessful: 'Call-off submitted',
      submitFailed: 'Submitting call-off failed: {message}',
    },
  },
  inventory: {
    title: 'Inventory',
    productSearchPlaceholder: 'Product or article',
    orderSearchPlaceholder: 'Purchase or sales order',
    showInStockOnly: 'In stock',
    download: 'Download',
    downloadFailed: 'Download failed: {message}',
    table: {
      customer: 'Customer',
      productName: 'Product',
      salesOrderId: 'Order',
      deliveryDate: 'Delivery date',
      orderedQuantity: 'Ordered',
      deliveredQuantity: 'Delivered',
      quantityInWarehouse: 'In stock',
      inCallOff: 'In call-off',
      expired: 'Storing time expired',
      expandable: {
        createdAtDate: 'Created at',
        orderedQuantity: 'Ordered quantity',
        deliveryDate: 'Delivery date',
        status: 'Status',
      },
    },
    tab: {
      openOrders: 'Open orders',
      allOrders: 'All orders',
    },
  },
  unit: {
    kg: 'kg',
    tk: 'pc',
    rull: 'reel',
    jm: 'm',
    pc: 'pc',
    reel: 'reel',
    m: 'm',
  },
  product: {
    title: 'Products',
    searchPlaceholder: 'Product or article',
    clientSelectPlaceholder: 'Client',
    activeFilter: {
      [ProductActiveFilter.ALL]: 'All',
      [ProductActiveFilter.ACTIVE]: 'Active',
      [ProductActiveFilter.INACTIVE]: 'Inactive',
    },
    table: {
      product: 'Product',
      alternative: 'Alternative',
      active: 'Active',
      deactivate: 'Deactivate product',
      pending: 'Product is being deactivated. This may take a while.',
      contactAdmin: 'The product is not active. If you wish to activate the product, please contact an Estiko specialist.',
    },
  },
  complaint: {
    add: 'Add',
    allCustomers: 'All customers',
    searchPlaceholder: 'Complaint, purchase order or invoice no',
    table: {
      customerName: 'Customer',
      id: 'Complaint no',
      purchaseOrderNumber: 'Sales order number',
      invoiceNumber: 'Invoice number',
      createdAt: 'Created',
      productCode: 'Product code',
      status: 'Status',
    },
    creation: {
      title: 'Select customer',
      customer: 'Customer',
      customerPlaceholder: 'Select customer',
      recipient: 'Recipient',
      recipientPlaceholder: 'Select recipient',
      noRecipient: 'No notifications will be sent to the customer',
      nextButton: 'Next',
    },
    status: {
      [ComplaintStatus.DRAFT]: 'Draft',
      [ComplaintStatus.UNDER_REVIEW]: 'Under review',
      [ComplaintStatus.SOLVED]: 'Solved',
      [ComplaintStatus.REJECTED]: 'Rejected',
      [ComplaintStatus.CANCELLED]: 'Cancelled',
    },
    decisionType: {
      [ComplaintDecisionType.RESOLVE]: 'Complaint accepted',
      [ComplaintDecisionType.DECLINE]: 'Complaint rejected',
      [ComplaintDecisionType.CANCEL]: 'Complaint cancelled',
    },
    details: {
      title: `Complaint: {id}`,
      customer: 'Customer',
      createdAt: 'Created',
      modifiedAt: 'Modified',
      packageNumber: 'Production number',
      purchaseOrderNumber: 'Sales order number',
      itemCode: 'Estiko product code',
      quantity: 'Reclamation quantity',
      productionNumber: 'Package number',
      complaintDetails: {
        title: 'Complaint details',
        content: 'Complaint content',
        claim: 'Claim',
        attachments: 'Attachments',
      },
      response: {
        title: 'Estiko response',
        cancelledTitle: 'Cancel reason',
        decision: 'Decision',
        description: 'Description',
        attachments: 'Attachment',
      },
      resolve: {
        decisionType: {
          [ComplaintDecisionType.RESOLVE]: 'Accept complaint',
          [ComplaintDecisionType.DECLINE]: 'Reject complaint',
        },
        title: 'Response',
        decision: 'Decision',
        decisionPlaceholder: 'Please enter describe decision',
        attachment: 'Attachment',
        attachmentPlaceholder: 'Click or drag file(s) to this area to upload',
        confirmButton: 'Confirm',
      },
    },
    edit: {
      title: 'New complaint',
      productionNumber: 'Production number (1)',
      itemCode: 'Article number (2)',
      unitNumber: 'Unit number (3)',
      unitNoPlaceholder: 'Unit number',
      productionNumberPlaceholder: 'Enter production numbers',
      invoiceNumber: 'Invoice number',
      orderNumber: 'Order confirmation number',
      quantity: 'Reclamation quantity',
      quantityPlaceholder: 'Enter quantity',
      unit: 'Unit',
      content: 'Complaint content',
      contentPlaceholder: 'Describe complaint',
      claim: 'Claim',
      claimPlaceholder: 'Proposed solution to claim',
      attachments: 'Attachments',
      details: 'Complaint details',
      dropAttachments: 'Click or drag file(s) to this area to upload',
      noAttachments: 'At least one attachment is required',
      imageDescription: '1. Package nr; 2. Product code; 3. Production number',
      save: 'Save',
      send: 'Send',
    },
    message: {
      saveSuccess: 'Complaint saved successfully',
      saveFailed: 'Unable to save complaint',
      submitSuccess: 'Complaint submitted successfully',
      submitFailed: 'Error occurred while submitting complaint',
      deleteSuccess: 'Complaint successfully deleted',
      deleteFailed: 'Error occurred while deleting complaint',
      cancelSuccess: 'Complaint successfully cancelled',
      cancelFailed: 'Error occurred while cancelling complaint',
      resolveSuccess: 'Complaint successfully accepted',
      resolveFailed: 'Complaint acceptance failed',
      rejectSuccess: 'Complaint successfully rejected',
      rejectFailed: 'Complaint rejection failed',
      productionSearch: {
        success: 'Production found',
        failed: 'Production not found',
      },
    },
    action: {
      resolveButton: 'Respond',
      delete: {
        button: 'Delete',
        title: 'Delete complaint',
        confirmation: 'Please confirm you want to delete this complaint?',
      },
      cancel: {
        button: 'Cancel',
        title: 'Cancel complaint',
        confirmation: 'Please confirm you want to cancel this complaint?',
      },
    },
  },
  user: {
    title: 'Users',
    viewClient: 'View client',
    portalSettings: {
      title: 'Portal settings',
      contractEn: 'EN contract url',
      contractEt: 'ET contract url',
      current: 'Current: ',
      type: {
        [AppSettingType.CONTRACT_URL_ET]: 'Estonian contract',
        [AppSettingType.CONTRACT_URL_EN]: 'English contract',
      },
    },
    searchPlaceholder: 'E-mail or name',
    typeFilter: {
      [UserTypeFilter.ALL]: 'All Permissions',
      [UserTypeFilter.CUSTOMER]: 'Customer',
      [UserTypeFilter.ESTIKO]: 'Manager',
    },
    type: {
      [UserType.CUSTOMER]: 'Customer',
      [UserType.ESTIKO]: 'Manager',
      [UserType.ADMIN]: 'Admin',
    },
    updateFromAx: 'Update users from AX',
    lastPullTime: 'Last updated at {time}',
    pullSuccess: 'Users have been updated',
    pullFailed: 'Error updating users',
    table: {
      name: 'Name',
      username: 'Username',
      email: 'E-mail',
      numberOfCustomers: 'Customers',
      permissions: 'Permissions',
      active: 'Active',
      isActive: 'Yes',
      notActive: 'No',
    },
    details: {
      userId: 'User ID',
      username: 'Username',
      adUsername: 'AD Username',
      email: 'Email',
      assistantEmail: 'Assistant Email',
      lang: 'Language',
      type: 'Type',
      active: 'Active',
      activatedAt: 'Activated at',
      admin: 'Admin',
      designer: 'Designer',
      loginAsCustomer: 'Login as customer',
      isNotificationsEnabled: 'Notifications enabled:',
      relatedCustomers: 'Customers',
      save: 'Save',
      isActive: 'Yes',
      notActive: 'No',
      deactivate: 'Deactivate',
      sendInvitation: 'Send Invitation',
      isAdmin: 'Yes',
      notAdmin: 'No',
      isDesigner: 'Yes',
      notDesigner: 'No',
      avatar: 'Avatar',
      uploadAvatar: 'Upload',
      message: {
        uploadAvatarSucceeded: 'Avatar uploaded',
        uploadAvatarFailed: 'Failed to upload avatar',
        enableNotificationsSucceeded: 'Notifications enabled',
        enableNotificationsFailed: 'Failed to enable notifications',
        disableNotificationsSucceeded: 'Notifications disabled',
        disableNotificationsFailed: 'Failed to disabled notifications',
        sendInvitationSucceeded: 'Invitation sent',
        sendInvitationFailed: 'Failed to send invitation',
        activateUserSucceeded: 'User activated',
        activateUserFailed: 'Failed to activate user',
        deactivateUserSucceeded: 'User deactivated',
        deactivateUserFailed: 'Failed to deactivate user',
        updateEmailsSucceeded: 'E-mails updated',
        updateEmailsFailed: 'Failed to update e-mails',
        enableAdminSucceeded: 'Admin permission granted',
        enableAdminFailed: 'Failed to grant permission',
        disableAdminSucceeded: 'Admin permission revoked',
        disableAdminFailed: 'Failed to revoke admin permission',
        adUsernameUpdated: 'AD username updated',
        adUsernameUpdateFailed: 'AD username already in use',
      },
    },
  },
  customer: {
    customerId: 'Customer id:',
    accountNumber: 'Account number:',
    language: 'Language:',
    country: 'Country:',
    active: 'Active:',
    yearContract: 'Year contract:',
    lastModified: 'Last modified:',
    type: 'User role',
    representativeUsers: 'Representative users',
    managerUsers: 'Manager users',
    addresses: 'Addresses',
    products: 'Products list',
    productList: {
      code: 'Item code',
      name: 'Name',
      externalItemId: 'External item id',
      defaultUnit: 'Default unit',
      salesUnit: 'Sales unit',
      coefficient: 'Coefficient',
      unit: 'Unit',
    },
    message: {
      unitSaveSuccess: 'Unit saved',
      unitSaveFailed: 'Failed to save unit',
    },
    standardProducts: 'Standard products',
    fileImportSettings: 'File import settings',
    address: {
      address: 'Address',
      type: 'Type',
      uses: 'Uses',
      default: 'Default',
      alternative: 'Alternative',
      uerInput: 'User input',
    },
    notifications: {
      customerNotFound: 'Customer does not exist',
    },
  },
  productAlternatives: {
    button: 'Alternatives',
    title: 'Sustainable alternatives',
    description:
      "The sustainability of your products is directly linked to your packaging decisions. Through our sustainability tool, you can explore and compare options to improve your packaging's recyclability. Upon completion, you can forward your intention to go more circular to your Sales Manager.",
    productGroupsTitle: 'Choose product group',
    standardProduct: 'Base product: ',
    alternativeProduct: 'Green alternative: ',
    additionalOptions: 'Additional sustainable options',
    noAlternativeGroups: 'Good job! Your products are already sustainable. If you have any further questions, feel free to contact your Sales Manager.',
    iscc: {
      title: 'ISCC',
      selectPlaceholder: 'Select ratio',
      description:
        "Estiko's obtained ISCC certificate enables us to supply packaging produced from certified renewable or recycled raw materials, helping you to achieve your sustainability goals.",
    },
    thinnerPackage: {
      title: 'Thinner package',
      select: 'Select thinner package',
      selected: 'Selected',
      description:
        'The easiest step to more sustainable packaging is reducing the amount of current material usage by downgauging. This can be done for both single web films and laminates.',
    },
    sustainablePrint: {
      title: 'Sustainable print',
      select: 'Select sustainable print',
      selected: 'Selected',
      description: 'Responsible printing through extended colour scale offers greater printing versatility and colour accuracy with enhanced sustainability.',
    },
    co2Offset: {
      title: 'CO\u2082 offset',
      select: 'Select CO\u2082 offset',
      selected: 'Selected',
      description:
        'The remaining part is neutralised with CO\u2082 credit, enabling you to communicate the climate-neutral status of your packaging to your consumers.',
      tooltip: 'The option becomes enabled when the proportion of ISCC materials in the product is maximised.',
    },
    confirmationModal: {
      text: 'Are you sure you want to change the product group?',
      buttonOk: 'Yes',
    },
    result: {
      title: 'Impact of your sustainable choices',
      sendRequest: 'Request a quote',
      sendSuccess: 'Thank you! The request for a green alternative has been forwarded to an Estiko specialist. We will contact you shortly.',
      sendFailed: 'Error occurred while submitting the request for a green alternative',
      card: {
        co2: {
          neutral: {
            title: 'carbon neutral',
            description: 'Your packaging is officially carbon neutral.',
          },
          partial: {
            title: 'reduced carbon footprint',
            description: 'Carbon footprint reduction achieved by:',
            downgauging: 'downgauging',
            isccCertifiedMaterials: 'using ISCC certified materials',
            responsiblePrinting: 'switching to responsible printing',
            co2Offset: 'CO\u2082 offset',
          },
        },
        recyclablePackage: {
          title: 'recyclable package',
          description: 'Your packaging supports circular economy by being fully recyclable in a designated recycling stream.',
        },
        recycledMaterial: {
          title: 'made from recycled material or renewable material',
          description: 'Your packaging contains post-consumer recycled (PCR) materials.',
        },
      },
      comparison: {
        title: 'The carbon footprint reduction is the equivalent of',
        condition: 'Assuming 1 ton of material production',
        disclaimer:
          'The tool and calculations provided are indicative and may not fully reflect the most suitable alternative for your products and packaging lines. For a detailed overview, please forward your chosen sustainable alternative request to your Sales Manager by clicking the button below.',
        hours: '{amount} hours',
        kgs: '{amount} kg',
        led: 'less burning of LED bulb',
        co2: 'less CO\u2082 emitted into the air',
      },
      notes: {
        title: 'Notes',
        description: 'Note or question to sales manager',
        send: 'Send'
      },
    },
  },
};

export default translations;
