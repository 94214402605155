import { Route, createRoutesFromElements, createBrowserRouter, Navigate } from 'react-router-dom';
import LoginPage from '../components/common/auth/LoginPage.tsx';
import Landing from '../components/Landing.tsx';
import Orders from '../components/order/list/Orders.tsx';
import Complaints from '../components/complaint/Complaints.tsx';
import Inventory from '../components/inventory/Inventory.tsx';
import Users from '../components/user/Users.tsx';
import Dashboard from '../components/dashboard/Dashboard.tsx';
import CallOffs from '../components/call-off/CallOffs.tsx';
import Products from '../components/product/Products.tsx';
import OrderViewResolver from '../components/order/details/OrderViewResolver.tsx';
import CallOffDetails from '../components/call-off/details/CallOffDetails.tsx';
import AuthenticatedRoutesWrapper from './AuthenticatedRoutesWrapper.tsx';
import ComplaintViewResolver from '../components/complaint/edit/ComplaintViewResolver.tsx';
import NotFound from '../components/common/NotFound.tsx';
import AdminRoutesWrapper from './AdminRoutesWrapper.tsx';
import UserDetails from '../components/user/details/UserDetails.tsx';
import CustomerDetails from '../components/customer/details/CustomerDetails.tsx';
import ProductAlternativesWrapper from '../components/product/alternatives/ProductAlternativesWrapper.tsx';
import RequestPasswordResetPage from '../components/common/auth/RequestPasswordResetPage.tsx';
import ResetPasswordPage from '../components/common/auth/ResetPasswordPage.tsx';
import CompleteInvitationPage from '../components/common/auth/CompleteInvitationPage.tsx';
import AdminPage from '../components/admin/AdminPage.tsx';

export const applicationRoutes = createRoutesFromElements(
  <Route path="/" element={<Landing />}>
    <Route element={<AuthenticatedRoutesWrapper />}>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="product-alternatives" element={<ProductAlternativesWrapper />} />
      <Route path="products" element={<Products />} />
      <Route path="call-offs" element={<CallOffs />} />
      <Route path="call-offs/new" element={<Inventory checkout={true} />} />
      <Route path="call-off/:callOffId" element={<CallOffDetails />} />
      <Route path="complaint/new" element={<ComplaintViewResolver isNew={true} />} />
      <Route path="complaint/:complaintId" element={<ComplaintViewResolver />} />
      <Route path="complaints" element={<Complaints />} />
      <Route path="inventory" element={<Inventory />} />
      <Route path="orders" element={<Orders />} />
      <Route path="order/:purchaseOrderId" element={<OrderViewResolver />} />
      <Route element={<AdminRoutesWrapper allowManager={true} />}>
        <Route path="users" element={<Users />} />
        <Route path="user/:userId" element={<UserDetails />} />
        <Route path="customer/:customerId" element={<CustomerDetails />} />
        <Route path="admin" element={<AdminPage />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
    <Route path="login" element={<LoginPage />} />
    <Route path="invitation/:hash" element={<CompleteInvitationPage />} />
    <Route path="request-password-reset" element={<RequestPasswordResetPage />} />
    <Route path="reset-password" element={<ResetPasswordPage />} />
    <Route path="/" element={<Navigate to="/dashboard" />} />
    <Route path="*" element={<NotFound />} />
  </Route>
);

export const router = createBrowserRouter(applicationRoutes);
