import PublisherApiPage from './publisher-api/PublisherApiPage.tsx';
import { Typography } from 'antd';

const AdminPage = (): JSX.Element => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography.Title level={4}>
        Admin
      </Typography.Title>
      <PublisherApiPage />
    </div>
  );
};

export default AdminPage;

