import { apiSlice } from './apiSlice.ts';
import { authCallback } from './authApiSlice.ts';
import { setLocale } from './authSlice.ts';
import { AuthenticatedUser } from './model/Auth.ts';
import { ApiResponse, CommonApiResponse, Language } from './model/Common.ts';
import {
  GetUsersRequest,
  SaveNotificationsRequest,
  ToggleAdminRequest,
  ToggleNotificationEmailsRequest,
  UpdateAdUsernameRequest,
  UploadAvatarRequest,
  UserListRow,
  UserViewData,
} from './model/User.ts';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<CommonApiResponse<UserListRow[]>, GetUsersRequest>({
      query: (request) => ({
        url: '/api/users/list',
        method: 'POST',
        body: request,
      }),
      providesTags: [{ type: 'Users', id: 'LIST' }],
    }),
    getUserViewData: builder.query<CommonApiResponse<UserViewData>, number>({
      query: (userId) => ({
        url: `/api/users/${userId}/get-user-view-data`,
        method: 'GET',
      }),
      providesTags: (_, __, userId) => [{ type: 'UserViewData', id: userId }],
    }),
    getLastPullTime: builder.query<CommonApiResponse<string>, void>({
      query: () => ({
        url: '/api/users/get-last-pull-time',
        method: 'GET',
      }),
      providesTags: ['UsersLastPullTime'],
    }),
    pullUsers: builder.mutation<CommonApiResponse<boolean>, void>({
      query: () => ({
        url: '/api/users/pull-users',
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }, 'UsersLastPullTime'],
    }),
    changeUserLanguage: builder.mutation<void, { userId: number; lang: Language }>({
      query: ({ userId, lang }) => ({
        url: `/api/users/${userId}/change-language?lang=${lang}`,
        method: 'POST',
      }),
      async onQueryStarted(request, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setLocale(request.lang));
        } catch {
          //
        }
      },
      invalidatesTags: [{type: "Orders"}]
    }),
    saveNotifications: builder.mutation<CommonApiResponse<boolean>, SaveNotificationsRequest>({
      query: (request) => ({
        url: '/api/users/save-notifications',
        method: 'POST',
        body: request,
      }),
      invalidatesTags: (_, __, request) => [{ type: 'UserViewData', id: request.userId }],
    }),
    toggleNotificationEmails: builder.mutation<CommonApiResponse<boolean>, ToggleNotificationEmailsRequest>({
      query: (request) => ({
        url: '/api/users/toggle-notification-emails',
        method: 'POST',
        body: request,
      }),
      invalidatesTags: (_, __, request) => [{ type: 'UserViewData', id: request.userId }],
    }),
    activateUser: builder.mutation<CommonApiResponse<boolean>, number>({
      query: (userId) => ({
        url: `/api/users/${userId}/activate-user`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, userId) => [{ type: 'UserViewData', id: userId }],
    }),
    deactivateUser: builder.mutation<CommonApiResponse<void>, number>({
      query: (userId) => ({
        url: `/api/users/${userId}/deactivate-user`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, userId) => [{ type: 'UserViewData', id: userId }],
    }),
    toggleAdmin: builder.mutation<CommonApiResponse<boolean>, ToggleAdminRequest>({
      query: (request) => ({
        url: `/api/users/toggle-admin`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: (_, __, request) => [{ type: 'UserViewData', id: request.userId }],
    }),
    updateAdUsername: builder.mutation<CommonApiResponse<boolean>, UpdateAdUsernameRequest>({
      query: (request) => ({
        url: `/api/users/update-ad-user`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: (_, __, request) => [{ type: 'UserViewData', id: request.userId }],
    }),
    sendInvitation: builder.mutation<CommonApiResponse<boolean>, number>({
      query: (userId) => ({
        url: `/api/users/${userId}/send-invitation`,
        method: 'POST',
      }),
    }),
    updateDefaultCustomerId: builder.mutation<CommonApiResponse<void>, number>({
      query: (customerId) => ({
        url: `/api/users/select-customer`,
        method: 'POST',
        body: { customerId },
      }),
    }),
    loginAsUser: builder.mutation<ApiResponse<AuthenticatedUser, string>, number>({
      query: (userId) => ({
        url: `/api/users/${userId}/login-as-user`,
        method: 'POST',
      }),
      invalidatesTags: ['ALL'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await authCallback(queryFulfilled, dispatch).then(() => {
          window.location.replace('/');
        });
      },
    }),
    uploadAvatar: builder.mutation<void, UploadAvatarRequest>({
      query: (request) => {
        const body = new FormData();
        body.append('file', request.file);
        return {
          url: `/api/users/${request.userId}/upload-avatar`,
          method: 'POST',
          body: body,
          formData: true,
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserViewDataQuery,
  useGetLastPullTimeQuery,
  usePullUsersMutation,
  useChangeUserLanguageMutation,
  useSaveNotificationsMutation,
  useToggleNotificationEmailsMutation,
  useActivateUserMutation,
  useDeactivateUserMutation,
  useToggleAdminMutation,
  useUpdateAdUsernameMutation,
  useSendInvitationMutation,
  useUpdateDefaultCustomerIdMutation,
  useLoginAsUserMutation,
  useUploadAvatarMutation,
} = userApiSlice;
