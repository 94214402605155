import { apiSlice } from './apiSlice.ts';
import { CommonApiResponse } from './model/Common.ts';
import { DownloadInventoryRequest, GetInventoryRequest, InventoryItem } from './model/InventoryItem.ts';

export const inventoryAbortController = new AbortController();

export const inventoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInventory: builder.query<CommonApiResponse<InventoryItem[]>, GetInventoryRequest>({
      query: (request) => ({
        url: '/api/inventory',
        params: request,
        timeout: 60000,
        // signal: inventoryAbortController.signal,
        method: 'GET',
      }),
      providesTags: ['ALL', 'Inventory'],
    }),
    downloadInventory: builder.mutation<void, DownloadInventoryRequest>({
      query: (request) => ({
        url: '/api/inventory/download',
        params: request,
        method: 'GET',
        timeout: 60000,
        // signal: inventoryAbortController.signal,
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const { useGetInventoryQuery, useDownloadInventoryMutation } = inventoryApiSlice;
