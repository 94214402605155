import { Button, Select } from 'antd';
import { useMemo } from 'react';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Language } from '../../../persistence/model/Common';
import { localeSelector, logout, selectCurrentUser, selectCurrentUserIsAdmin, setLocale } from '../../../persistence/authSlice.ts';
import { useDispatch, useSelector } from 'react-redux';
import FlexBox from '../FlexBox.tsx';
import './UserMenu.css';
import { UserType } from '../../../persistence/model/Auth.ts';
import { useChangeUserLanguageMutation } from '../../../persistence/userApiSlice.ts';
import { useNavigate } from 'react-router-dom';

const UserMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector(localeSelector);
  const user = useSelector(selectCurrentUser);
  const isAdmin = useSelector(selectCurrentUserIsAdmin);
  const [changeLangQuery, { isLoading }] = useChangeUserLanguageMutation();

  const languageSelect = useMemo(
    () => (
      <Select<Language>
        size="large"
        value={lang}
        loading={isLoading}
        onChange={(lang) => {
          if (user?.userId) {
            changeLangQuery({ userId: user?.userId, lang: lang });
          } else {
            dispatch(setLocale(lang));
          }
        }}
        options={Object.keys(Language).map((lang) => ({ label: lang, value: lang }))}
      />
    ),
    [changeLangQuery, dispatch, isLoading, lang, user?.userId],
  );

  const logoutButton = useMemo(
    () => (
      <Button size="large" className="logout-button" onClick={() => dispatch(logout())}>
        <LogoutOutlined />
      </Button>
    ),
    [dispatch],
  );

  const adminButton = useMemo(
    () => (
      <Button size="large" className="logout-button" onClick={() => navigate('/admin')}>
        <SettingOutlined />
      </Button>
    ),
    [navigate],
  );

  const representedCustomer = useMemo(
    () => ((user?.type === UserType.CUSTOMER && user.representedCustomers?.length) === 1 ? user.representedCustomers.find((c) => !!c) : undefined),
    [user],
  );

  const userAndCustomerName = useMemo(
    () => <h3 style={{ cursor: 'pointer' }}>{`${user?.name || ''}${representedCustomer ? `, ${representedCustomer.name?.trim()}` : ''}`}</h3>,
    [representedCustomer, user?.name],
  );

  return (
    <FlexBox direction="horizontal" style={{ gap: '20px', alignItems: 'center' }}>
      {languageSelect}
      {isAdmin && adminButton}
      {user && (
        <>
          {userAndCustomerName}
          {logoutButton}
        </>
      )}
    </FlexBox>
  );
};

export default UserMenu;
