import { apiSlice } from './apiSlice.ts';

export const adminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    healthCheck: builder.query<{ status: string, tables: any[] }, void>({
      query: () => ({
        url: `/api/publisher-api/health-check`,
      }),
      providesTags: ['ALL', 'HealthCheck']
    }),
    syncTables: builder.mutation<void, string[]>({
      query: (tableNames: string[]) => ({
        url: `/api/publisher-api/sync-tables`,
        params: { tables: tableNames },
        method: 'POST'
      }),
      invalidatesTags: ['HealthCheck']
    }),
  }),
});

export const { useHealthCheckQuery, useSyncTablesMutation } = adminApiSlice;
