import { Audit } from './Common.ts';

export type AppSettingRequest = {
  type: AppSettingType;
  value: string;
}

export enum AppSettingType {
  CONTRACT_URL_ET = "CONTRACT_URL_ET",
  CONTRACT_URL_EN = "CONTRACT_URL_EN"
}

export type AppSettingsResponse = {
  id: number;
  type: AppSettingType,
  value: string,
} & Audit