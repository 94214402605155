import { App, Button, Form, Input, Modal, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback } from 'react';
import { translate } from '../../translations/TranslationUtils.ts';
import { useGetAppSettingsQuery, useSaveAppSettingMutation } from '../../persistence/appSettingsApiSlice.ts';
import { AppSettingType } from '../../persistence/model/AppSetting.ts';

type PortalSettingsModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
};

type FormItemRowProps = {
  currentValue: string;
  type: AppSettingType;
};

const PortalSettingsModal = ({ isModalOpen, onModalClose }: PortalSettingsModalProps): JSX.Element => {
  const intl = useIntl();
  const { message } = App.useApp();
  const [saveContractUrl] = useSaveAppSettingMutation();
  const { data: appSettings } = useGetAppSettingsQuery();
  const [form] = Form.useForm();

  const submitHandler = useCallback(
    (type: AppSettingType) => {
      form
        .validateFields([translate(intl, 'user.portalSettings.type.' + type)])
        .then((values) => {
          saveContractUrl({ type, value: values[translate(intl, 'user.portalSettings.type.' + type)] })
            .then(() => {
              message.success(translate(intl, 'order.message.confirmSuccess'));
            })
            .catch(() => {
              message.error(translate(intl, 'order.message.confirmError'));
            });
        })
        .catch();
    },
    [intl, message, saveContractUrl, form]
  );

  const FormItemRow = ({ currentValue, type }: FormItemRowProps) => {
    return (
      <>
        <Typography.Title level={5}>
          <FormattedMessage id={'user.portalSettings.type.' + type} />
        </Typography.Title>
        <span style={{ fontWeight: 600 }}>
          <FormattedMessage id={'user.portalSettings.current'} />
        </span>
        <a href={currentValue} target="_blank">
          {currentValue}
        </a>
        <Form.Item name={translate(intl, 'user.portalSettings.type.' + type)} rules={[{ required: true }, { type: 'url' }]} style={{ padding: 0 }}>
          <Input style={{ width: 250 }} placeholder={translate(intl, 'user.portalSettings.type.' + type)} />
        </Form.Item>
        <Form.Item style={{ borderBottom: '1px solid #EAEAEA', paddingBottom: 20 }}>
          <Button type={'primary'} onClick={() => submitHandler(type)}>
            <FormattedMessage id="common.save" />
          </Button>
        </Form.Item>
      </>
    );
  };

  return (
    <Modal width={500} open={isModalOpen} onCancel={() => onModalClose()} title={<FormattedMessage id="user.portalSettings.title" />} footer={''}>
      <Form form={form}>{appSettings?.data?.map((s) => <FormItemRow key={s.type.toString()} currentValue={s.value} type={s.type} />)}</Form>
    </Modal>
  );
};

export default PortalSettingsModal;
